import React, { useState, useEffect } from 'react';
import ReactConfetti from 'react-confetti';

const Confetti = () => {
    const [windowDimension, setWindowDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    const [showConfetti, setShowConfetti] = useState(true); // Controls whether confetti is displayed

    const detectSize = () => {
        setWindowDimension({
            width: window.innerWidth,
            height: window.innerHeight
        });
    };

    useEffect(() => {
        window.addEventListener('resize', detectSize);
        return () => {
            window.removeEventListener('resize', detectSize);
        };
    }, []);

    useEffect(() => {
        // Set a timeout to stop the confetti after 5 seconds
        const timer = setTimeout(() => {
            setShowConfetti(false);
        }, 7000);

        return () => clearTimeout(timer); // Cleanup the timer
    }, []);

    return (
        <>
            {showConfetti && (
                <ReactConfetti
                    width={windowDimension.width}
                    height={windowDimension.height}
                    // tweenDuration={1000}
                />
            )}
        </>
    );
};

export default Confetti;
